import { HeaderType, TabsSelectModule, UIPage } from 'shared-lib/core';
import { HeaderModule, Page, TabbedPageModules } from '@yoimo/interfaces';
import {
  isTabbedPageModules,
  getSortedPageTabs,
} from '@yoimo/client-sdk/pages';

function tabbedPageModulesToTabSelectModule(
  tabbedModules: TabbedPageModules,
  selectedTabId?: string
): TabsSelectModule {
  let defaultToSelect = -1;
  let selectedByTabId = -1;

  const result = {
    moduleType: 'TABS_SELECT' as const,
    tabs: getSortedPageTabs(tabbedModules).map((t, idx) => {
      if (t.id === selectedTabId) {
        selectedByTabId = idx;
      }

      if (defaultToSelect === -1 && t.default) {
        defaultToSelect = idx;
      }

      return {
        title: t.name,
        anchor: t.id,
        tabId: t.id,
        moduleData: t.modules,
        selected: false,
      };
    }),
  };

  const idx =
    selectedByTabId >= 0
      ? selectedByTabId
      : defaultToSelect >= 0
        ? defaultToSelect
        : 0;

  result.tabs[idx].selected = true;
  return result;
}

export function mapPageToUIPage(
  page: Page,
  header: HeaderModule,
  headerType: HeaderType,
  selectedTabId?: string | null
): UIPage {
  let modules: UIPage['modules'];
  if (isTabbedPageModules(page.modules)) {
    modules = tabbedPageModulesToTabSelectModule(
      page.modules,
      selectedTabId ?? undefined
    );
  } else {
    modules = page.modules;
  }
  return {
    ...page,
    modules,
    header: { ...header, type: headerType },
  };
}
